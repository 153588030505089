<!--已购买 申请续方-->
<template>
  <div>
    <div class="red-box">
      <p v-if="dataSource.orderzt == 2">
        药品已退款，如有需要可申请续方再次购买
      </p>
      <p v-else>
        药品已购买，如有需要可申请续方再次购买
      </p>
    </div>
    <div class="page-box">
      <div class="card-box">
        <div class="row">
          <div class="col bold">
            电子处方编号
          </div>
          <div class="col middle-color bold size24">
            {{ dataSource.cfh }}
          </div>
          <!--                    <div class="col blue-btn">-->
          <!--                      查看原始处方-->
          <!--                    </div>-->
        </div>
        <div class="row mt40">
          <div class="col dark-color bold size28">
            药品签收码
          </div>
          <div class="col middle-color bold size32">
            {{ dataSource.shm }}
          </div>
          <div class="col blue-btn" @click="onShow">
            查看物流
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="title">
          患者信息
        </div>
        <p class="desc">
          {{ dataSource.hzxm }} {{ encryptPhoneNum(dataSource.sjhm) }}
        </p>
        <div class="title mt40">
          诊断结果
        </div>
        <p class="desc">
          {{ dataSource.zdmc }}
        </p>
      </div>
      <div class="card-box">
        <div class="title">
          用药建议
        </div>
        <drugItem :data="dataSource.details || []" />
      </div>
      <div class="card-box">
        <div class="title">
          开方信息
        </div>
        <div class="row mt20">
          <div class="left">
            开方医师：
          </div>
          <div class="right">
            {{ dataSource.kfysxm }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            审方医师：
          </div>
          <div class="right">
            {{ dataSource.ysxm }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            开方时间：
          </div>
          <div class="right">
            {{ dataSource.kfsj }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            审方时间：
          </div>
          <div class="right">
            {{ dataSource.sfsj }}
          </div>
        </div>
      </div>
      <div v-if="dataSource.orderzt == 1" class="card-box right">
        <div class="row">
          <div class="title">
            申请售后
          </div>
          <div class="col blue-btn right" @click="onCancelShow">
            退款
          </div>
        </div>
        <div class="row">
          <div class="col tips size24 mt20">
            由于药品的特殊性，如非错发，漏发药品的情况下，药品一经发出不得退换。
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="footer">
      <van-button type="info" round size="small" class="fixedBtn">
        立即支付
      </van-button>
    </div> -->
    <van-popup v-model="visible" round>
      <div class="card-box">
        <div class="title">
          配送信息
        </div>
        <div class="row mt20   ">
          <div class="left">
            物流公司名称：
          </div>
          <div class="right">
            {{ dataSource.kdgs }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            物流快递单号：
          </div>
          <div class="right">
            {{ dataSource.kddh }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            配送状态：
          </div>
          <div class="right">
            {{ dataSource.psztmc }}
          </div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="left">-->
        <!--            配送开始时间：-->
        <!--          </div>-->
        <!--          <div class="right">-->
        <!--            {{ dataSource.pskssj }}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="row">-->
        <!--          <div class="left">-->
        <!--            配送结束时间：-->
        <!--          </div>-->
        <!--          <div class="right">-->
        <!--            {{ dataSource.psjssj }}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </van-popup>
    <van-dialog
      v-model="isCancelVisible"
      :title="'提示'"
      show-confirm-button
      show-cancel-button
      theme="round-button"
      confirm-button-text="确定"
      cancel-button-text="取消"
      confirm-button-color="#3A8AE5"
      cancel-button-color="#DDDDDD"
      @confirm="sure"
    >
      <div class="reason-list">
        请确定是否要退款！
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import { encryptPhoneNum } from '@/utils'
import { appointModel } from '@/api/appoint'
import { CONSTANT } from '@/utils/constant'

export default {
  name: 'Purchased',
  data() {
    return {
      dataSource: {},
      visible: false,
      isCancelVisible: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const { data } = await userModel.preOrderDetail(this.$route.query.orderId)
      this.dataSource = data || {}
    },
    encryptPhoneNum(num) {
      return num ? encryptPhoneNum(num) : ''
    },
    onShow() {
      this.visible = true
    },
    onCancelShow() {
      this.isCancelVisible = true
    },
    sure() {
      appointModel.preRefundApply({cfddbm:this.dataSource.cfddbm}).then((res) =>{
        if (res.code === CONSTANT.SUCCESS_CODE) {
          this.$toast('退费申请已提交!')
          this.$router.back()
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";

.van-popup {
  width: 75%;
  padding: 12px 20px;
}

.red-box {
  width: 100%;
  height: 168px;
  background: #DC3030;

  p {
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 88px;
  }
}

.page-box {
  padding: 0 32px 50px;
  box-sizing: border-box;
  position: relative;
  top: -82px;
}

.card-box {
  width: 100%;
  background: #FFF;
  border-radius: 12px;
  padding: 40px 32px;
  margin-bottom: 24px;
  box-sizing: border-box;
  z-index: 2;

  .title {
    font-size: 32px;
    font-weight: bold;
    color: @dark-font-color;
  }

  .desc {
    font-size: 28px;
    color: #666666;
    margin: 12px 0px;
  }

  .row {
    display: flex;
    line-height: 48px;
    justify-content: space-between;
    color: @dark-font-color;
    font-size: 28px;

    .left {
      line-height: 48px;
      text-align: left;
    }

    .right {
      text-align: right;
    }

    .tips {
      color: #F5955F;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 98px;
  background: #F2F2F2;
  text-align: right;
  padding: 16px 32px;
  box-sizing: border-box;

}

.fixedBtn {
  position: fixed;
  bottom: 15px;
  width: 686px;
  height: 68px;
  line-height: 68px;
  background: #3A8AE5;
  color: #ffffff;
  border-radius: 31px;
  margin: 0 auto;
}

.fl-right {
  float: right;
  margin-top: 6px;
}


.reason-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding:0px 50px;
  margin: 40px 0 ;
}
</style>
